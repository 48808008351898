import { cx } from "@libs/utils/cx";

type ProgressColor = "archyBlue-500" | "archyBlue-200" | "green-500";

const cxProgressColors: Record<ProgressColor, string> = {
  ["archyBlue-500"]: "bg-archyBlue-500",
  ["archyBlue-200"]: "bg-archyBlue-200",
  ["green-500"]: "bg-green-500",
};

export const LinearProgress: React.FC<{ percent: number; progressColor?: ProgressColor }> = ({
  percent,
  progressColor = "archyBlue-500",
}) => {
  return (
    <div
      className={`
        flex-start
        flex
        h-2
        w-full
        overflow-hidden
        rounded-full
        bg-slate-200
        font-sans
        text-xs
        font-medium
      `}
    >
      <div
        style={{ width: `${percent * 100}%` }}
        className={cx(
          `flex
           items-center
           justify-center
           h-full
           overflow-hidden
           text-white
           break-all
           rounded-full`,
          cxProgressColors[progressColor]
        )}
      />
    </div>
  );
};

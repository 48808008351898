import { forwardRef, useState } from "react";
import { cxIconStyles } from "@libs/components/UI/formFieldStyle";
import { ReactComponent as Hide } from "@libs/assets/icons/hide.svg";
import { ReactComponent as Show } from "@libs/assets/icons/eye.svg";
import { FormFieldInput, FormFieldInputProps } from "@libs/components/UI/FormFieldInput";

export const FormFieldPassword = forwardRef<HTMLInputElement, FormFieldInputProps>((props, ref) => {
  const [type, setType] = useState<"password" | "text">("password");
  const iconClassName = cxIconStyles.element({
    disabled: props.disabled,
  });

  return (
    <FormFieldInput {...props} ref={ref} type={type}>
      <button
        type="button"
        tabIndex={-1}
        className="absolute bottom-full z-0 right-1 -translate-y-1.5"
        onClick={() => setType((last) => (last === "password" ? "text" : "password"))}
      >
        {type === "password" ? <Hide className={iconClassName} /> : <Show className={iconClassName} />}
      </button>
    </FormFieldInput>
  );
});

import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import React, { useCallback, useMemo, useRef } from "react";
import { cx } from "@libs/utils/cx";
import { passRefs } from "@libs/utils/forms";
import { useClickOutsideTracker } from "@libs/contexts/ClickOutsideListenerContext";
import { usePortalId } from "@libs/contexts/PortalContext";

const DatePickerBaseWithRef = <
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
>(
  props: ReactDatePickerProps<CustomModifierNames, WithRange>,
  ref: React.ForwardedRef<DatePicker>
) => {
  const portalId = usePortalId();
  const clickOutsideProps = useClickOutsideTracker();

  const datePickerRef = useRef<DatePicker>(null);

  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Tab") {
      datePickerRef.current?.setOpen(false);
    }
  }, []);
  const refs: (current: DatePicker) => void = useMemo(() => passRefs([ref, datePickerRef]), [ref]);

  return (
    <DatePicker
      portalId={portalId}
      popperContainer={(popperProps) => (
        <div {...popperProps} className="z-10 relative" {...clickOutsideProps} />
      )}
      autoComplete="off"
      {...props}
      wrapperClassName={cx("react-datepicker-archy-datepicker-wrapper", props.wrapperClassName)}
      popperClassName={cx(`react-datepicker-archy-popper`, props.popperClassName)}
      calendarClassName={cx(
        `react-datepicker-archy-calendar`,
        props.selectsRange ? "react-datepicker-archy-range" : undefined,
        props.calendarClassName
      )}
      ref={refs}
      onKeyDown={onKeyDown}
    />
  );
};

export const DatePickerBase = React.forwardRef(DatePickerBaseWithRef) as <
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
>(
  props: ReactDatePickerProps<CustomModifierNames, WithRange> & { ref?: React.ForwardedRef<DatePicker> }
) => ReturnType<typeof DatePickerBaseWithRef>;

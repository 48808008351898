import React from "react";
import {
  FormFieldCurrencyInput,
  FormFieldCurrencyInputProps,
} from "@libs/components/UI/FormFieldCurrencyInput";
import { isNullish } from "@libs/utils/types";
import { centsToDollars, dollarsToCents } from "@libs/utils/currency";

// To be used to edit a currency value in cents, since this is what all of our APIs return and consume.

// 'onValueChange' will only fire when a valid cents number is entered. (no event on "10." for example)
// value expects a number in cents or undefined.
// onValueChange provides new value in cents, or undefined if invalid.

export const FormFieldCentsInput: React.FC<
  Omit<FormFieldCurrencyInputProps, "value" | "onValueChange"> & {
    value?: number;
    onValueChange: (cents?: number) => void;
  }
> = ({ onValueChange, value, ...rest }) => {
  const [rawInputValue, setRawInputValue] = React.useState<number | string | undefined>(
    isNullish(value) ? undefined : centsToDollars(value)
  );

  const handleInputChange = React.useCallback(
    (val?: string) => {
      const parsed = Number(val);

      setRawInputValue(val);

      if (Number.isNaN(parsed)) {
        onValueChange(undefined);
      } else if (Number(rawInputValue) !== parsed) {
        onValueChange(dollarsToCents(parsed));
      }
    },
    [onValueChange, rawInputValue]
  );

  React.useEffect(() => {
    setRawInputValue(isNullish(value) ? undefined : centsToDollars(value));
  }, [value]);

  return (
    <FormFieldCurrencyInput
      onValueChange={handleInputChange}
      value={isNullish(rawInputValue) ? undefined : `${rawInputValue}`}
      {...rest}
    />
  );
};

import { Trans, useTranslation } from "react-i18next";
import { cx } from "@libs/utils/cx";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { useAccount } from "@libs/contexts/AccountContext";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getPatientAppointments } from "api/patient/queries";
import { getSelfBookingConfigV2 } from "api/self-booking/queries";
import { getInvoicesInfiniteQuery, getPaymentProfiles } from "api/billing/queries";
import { semibold16, semibold20, semibold14 } from "assets/styles/textSize";
import { InternalLink } from "components/UI/Link";
import { AppointmentCard, NoticeCard } from "components/UI/Cards";
import { NoAppointmentsCard } from "components/AppointmentsRoute/NoAppointmentsCard";
import { InvoiceCard } from "components/Billing/InvoiceCard";
import { OnboardingCard } from "components/HomeRoute/OnboardingCard";
import { paths } from "router/paths";
import { useCurrentPatient } from "contexts/PatientContext";

const cxStyles = {
  cardHeader: "flex justify-between px-4",
  section: "px-4",
  card: `
    border
    border-slate-300
    p-3
    rounded-md
    ml-4
    last:mr-4
    mb-2
    hover:border-secondaryTheme
    bg-white
    min-w-72
  `,
};

const HomeSection: React.FC<{
  title: string;
  children: React.ReactNode;
  viewAllLink: string;
  empty: boolean;
  emptyMessage: React.ReactNode;
}> = ({ title, children, viewAllLink, empty, emptyMessage }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3">
      <div className={cxStyles.cardHeader}>
        <div className={semibold16}>{title}</div>
        {!empty && (
          <InternalLink to={viewAllLink} className={semibold14}>
            {t("home.page.viewAllLink")}
          </InternalLink>
        )}
      </div>
      <div
        className={`
          w-full
          flex
          overflow-x-auto
          md:flex-col
          md:overflow-x-visible
        `}
      >
        {empty ? emptyMessage : children}
      </div>
    </div>
  );
};

export const HomeRoute: React.FC = () => {
  const { t } = useTranslation();
  const { practiceId, id: patientId } = useAccount();
  const practice = useCurrentPractice();
  const patient = useCurrentPatient();
  const unpaidInvoicesQuery = useInfiniteApiQuery(
    getInvoicesInfiniteQuery({
      args: {
        patientId,
        practiceId,
        pageNumber: 1,
        pageSize: 20,
        includePayments: true,
        includePayerInfo: true,
        includeOrders: false,
        states: ["FINALIZED", "PARTIALLY_PAID"],
      },
    })
  );
  const unpaidInvoices = useFlattenPages(unpaidInvoicesQuery.data);

  const [aptsQuery, paymentProfilesQuery, selfBookableConfigQuery] = useApiQueries([
    getPatientAppointments({
      args: { patientId, practiceId, query: { dateMode: "FUTURE", pageNumber: 1, pageSize: 20 } },
    }),
    getPaymentProfiles({ args: { patientId, practiceId } }),
    getSelfBookingConfigV2({
      args: {
        practiceUuid: practice.uuid,
      },
    }),
  ]);
  const { data: apts } = aptsQuery;

  return (
    <QueryResult queries={[aptsQuery, paymentProfilesQuery, selfBookableConfigQuery]}>
      <div className="flex flex-col md:items-center w-full">
        <div className="flex flex-col gap-4 md:max-w-lg">
          <div className={cx("flex flex-col gap-2 mt-4", cxStyles.section)}>
            <div className={semibold20}>
              <Trans
                i18nKey="app.pages.user.salutation"
                values={{ patientName: patient.personalDetails.fullName?.split(" ")[0] }}
                tOptions={{ interpolation: { escapeValue: true } }}
                shouldUnescape
              />
            </div>
            <div>{t("home.page.welcome")}</div>
          </div>
          <OnboardingCard onboardingState={patient.onboardingState} />
          {(unpaidInvoices?.length ?? 0) > 0 && (
            <HomeSection
              title={t("home.page.PendingPayments")}
              viewAllLink={paths.invoices()}
              empty={unpaidInvoices?.length === 0}
              emptyMessage={null}
            >
              {unpaidInvoices?.map((invoice) => {
                return <InvoiceCard inCarousel key={invoice.uuid} invoice={invoice} />;
              })}
            </HomeSection>
          )}
          <HomeSection
            title={t("home.page.upcomingApts")}
            viewAllLink={paths.appointments()}
            empty={apts?.length === 0}
            emptyMessage={
              <NoAppointmentsCard
                className="mx-4"
                appointmentType="FUTURE"
                selfBookingEnabled={selfBookableConfigQuery.data?.enabled || false}
              />
            }
          >
            {(apts?.length ?? 0) > 0
              ? apts?.map((apt) => {
                  return <AppointmentCard inCarousel key={apt.id} appointment={apt} />;
                })
              : null}
          </HomeSection>
          {paymentProfilesQuery.data?.length === 0 && (
            <NoticeCard
              title={t("home.page.updatePaymentInfo.title")}
              body={t("home.page.updatePaymentInfo.body")}
              linkText={t("Add Payment Method")}
              linkTo={paths.addPaymentMethod({ from: paths.home() })}
            />
          )}
          <NoticeCard
            title={t("home.page.updateMedicalInfo.title")}
            body={t("home.page.updateMedicalInfo.body")}
            linkText={t("home.page.updateMedicalInfo.link")}
            linkTo={paths.medicalHistory()}
          />
        </div>
      </div>
    </QueryResult>
  );
};

import Skeleton from "react-loading-skeleton";
import DatePicker from "react-datepicker";

import { addDays, subDays, isSameDay } from "date-fns";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PracticeInfoVO, SelfBookingConfigVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import {
  formatAsISODate,
  formatShortDayOfMonth,
  formattedDateToISODate,
  getLocalDate,
  nowInTimezone,
} from "@libs/utils/date";
import { formatPhoneNumber } from "@libs/utils/phone";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as RightCaret } from "@libs/assets/icons/right-caret.svg";
import { ReactComponent as LeftCaret } from "@libs/assets/icons/left-caret.svg";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { isDefined } from "@libs/utils/types";
import { getSelfBookableOpenSlotsV2, getSelfBookableProvidersV2 } from "api/self-booking/queries";
import { ProviderSlots } from "components/SelfBooking/ProviderSlots";
import { DatePickerNavInput } from "components/SelfBooking/DatePickerNavInput";
import { useResponsiveBreakpoint } from "hooks/useResponsiveBreakpoint";
import { selfBookingCxStyles } from "components/SelfBooking/utils";
import { PoweredBy } from "components/UI/PoweredBy";
import { AnonPatientHeader } from "components/UI/AnonPatientHeader";
import { AnonUserMessagePage } from "components/UI/AnonPatientMessagePage";
import { SelfBookingV1 } from "storage/selfBooking";

const DESKTOP_END_DATE_DAYS = 6;
const DESKTOP_DAY_ADJUSTMENT = 7;

const handleFormatValue = (startDate?: string, endDate?: Date) => {
  if (startDate) {
    let returnValue = formatShortDayOfMonth(getLocalDate(formattedDateToISODate(startDate)));

    if (endDate) {
      returnValue += `— ${formatShortDayOfMonth(endDate)}`;
    }

    return returnValue;
  }

  return "";
};

export const SelfBookingStep2: React.FC<{
  practiceInfo: PracticeInfoVO;
  config: SelfBookingConfigVO;
  state: Partial<SelfBookingV1>;
  setState: (updates: Partial<SelfBookingV1>) => void;
  onNextStep: () => void;
}> = ({ practiceInfo, config, onNextStep, state, setState }) => {
  const today = new Date();

  const { t } = useTranslation();

  const { screenMatches } = useResponsiveBreakpoint();

  const mediumScreen = screenMatches.has("md");

  const appointmentCategoryId = Number(state.categoryId);

  const [searchDate, setSearchDate] = useState(() =>
    state.isoDate == null ? nowInTimezone(practiceInfo.timezoneId) : getLocalDate(state.isoDate)
  );

  const endDate = addDays(searchDate, DESKTOP_END_DATE_DAYS);

  const dayAdjustment = mediumScreen ? DESKTOP_DAY_ADJUSTMENT : 1;

  const handlePrevDay = () => {
    const adjustedDate = subDays(searchDate, dayAdjustment);

    setSearchDate(adjustedDate);
  };

  const handleNextDay = () => {
    const adjustedDate = addDays(searchDate, dayAdjustment);

    setSearchDate(adjustedDate);
  };

  const [selfBookableOpenSlotsQuery, selfBookableProvidersQuery] = useApiQueries([
    getSelfBookableOpenSlotsV2({
      args: {
        practiceUuid: practiceInfo.uuid,
        data: {
          selfBookingPatientType: state.existingPatient ? "EXISTING" : "NEW",
          appointmentCategoryId,
          startDate: formatAsISODate(searchDate),
        },
      },
    }),
    getSelfBookableProvidersV2({
      args: {
        practiceUuid: practiceInfo.uuid,
        query: {
          selfBookingPatientType: state.existingPatient ? "EXISTING" : "NEW",
          carrierId: config.useCustomCarriers ? undefined : state.carrierId,
          appointmentCategoryId,
          startDate: formatAsISODate(searchDate),
        },
      },
    }),
  ]);

  const handleSelect = (providerId: number, providerName: string, isoDate: string, isoTime: string) => {
    setState({ providerId, providerName, isoDate, isoTime });
    onNextStep();
  };

  const duration = config.appointmentCategories.find(
    (aptCat) => aptCat.id === appointmentCategoryId
  )?.duration;

  const providerSlots = useMemo(() => {
    return selfBookableProvidersQuery.data
      ?.map((provider) => {
        const slots = selfBookableOpenSlotsQuery.data?.providerSlots?.[provider.id];

        if (slots?.length) {
          return {
            provider,
            slots,
          };
        }

        return null;
      })
      .filter(isDefined);
  }, [selfBookableProvidersQuery.data, selfBookableOpenSlotsQuery.data]);
  const phoneNumber = practiceInfo.phoneNumber || "";

  return (
    <div className={selfBookingCxStyles.mainStepContainer}>
      <AnonPatientHeader practiceInfo={practiceInfo}>
        <div className="flex justify-between mt-3">
          <DatePicker
            onChange={(e) => e && setSearchDate(e)}
            selected={searchDate}
            minDate={today}
            startDate={searchDate}
            endDate={mediumScreen ? endDate : undefined}
            customInput={
              <DatePickerNavInput
                onFormatValue={(curStartDate) =>
                  handleFormatValue(curStartDate, mediumScreen ? endDate : undefined)
                }
                className={`
                  text-primaryTheme
                  border
                  border-greyLighter
                  rounded
                  py-2
                  px-3
                  h-10
                  enabled:focus:shadow-focusedPrimary
                  enabled:focus:outline-none
                `}
              />
            }
            popperClassName={`
              react-datepicker-archy-popper
              react-datepicker-archy-calendar
            `}
          />
          <div className="flex gap-2">
            <ButtonIcon
              className={selfBookingCxStyles.button}
              theme="primary"
              onClick={handlePrevDay}
              disabled={isSameDay(searchDate, today)}
              SvgIcon={LeftCaret}
            />
            <ButtonIcon
              className={selfBookingCxStyles.button}
              theme="primary"
              onClick={handleNextDay}
              SvgIcon={RightCaret}
            />
          </div>
        </div>
      </AnonPatientHeader>
      <QueryResult
        queries={[selfBookableOpenSlotsQuery, selfBookableProvidersQuery]}
        loading={
          <div className="w-full h-full">
            <Skeleton className="w-full h-full p-6" />
          </div>
        }
      >
        {providerSlots?.length && duration ? (
          <>
            <div className={cx("p-6 flex flex-col gap-3", selfBookingCxStyles.container)}>
              {providerSlots.map(({ slots, provider }) => {
                return (
                  <ProviderSlots
                    key={provider.id}
                    provider={provider}
                    slots={slots}
                    practice={practiceInfo}
                    date={searchDate}
                    displayDays={mediumScreen ? DESKTOP_DAY_ADJUSTMENT : 1}
                    duration={duration}
                    onSelect={handleSelect}
                    onSetDate={setSearchDate}
                    displayInOutNetworkDetail={
                      config.insuranceIntakeEnabled
                        ? state.carrierId !== 0 && !config.useCustomCarriers
                        : false
                    }
                  />
                );
              })}

              <div className="flex justify-center p-6 mt-6">
                <PoweredBy className="text-secondaryTheme" />
              </div>
            </div>
          </>
        ) : (
          <AnonUserMessagePage
            logoUrl={practiceInfo.logo?.url}
            homeUrl={practiceInfo.website}
            title={t("selfBooking.unavailableTitle")}
            subTitle={
              <div className="text-center">
                {t("selfBooking.unavailableText")}{" "}
                <a href={`tel:${phoneNumber}`}>{formatPhoneNumber(phoneNumber)}</a>
              </div>
            }
          />
        )}
      </QueryResult>
    </div>
  );
};

import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { formatAsISODate } from "@libs/utils/date";
import { ApiErrorResponse } from "@libs/@types/api";
import { StatusCodes } from "@libs/utils/statusCodes";
import { AnonUserMessagePage } from "components/UI/AnonPatientMessagePage";
import { DateOfBirthForm } from "components/DateOfBirthForm";

interface Props {
  practice: PracticeInfoVO;
  onDateChange: (date: string | undefined) => void;
  onSubmit: () => void;
  isLoading: boolean;
  tasksQueryError: ApiErrorResponse | null;
}

export const PatientFormTasksDobPage: FC<Props> = ({
  practice,
  tasksQueryError,
  isLoading,
  onDateChange,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const errorMessage = useMemo(() => {
    if (tasksQueryError) {
      if (
        tasksQueryError.status === StatusCodes.FORBIDDEN &&
        tasksQueryError.error.errors?.[0]?.message === "DOB is invalid"
      ) {
        return t("Sorry this does not match the patient's date of birth we have on record.");
      }

      return t("Sorry, something went wrong. Please try again.");
    }

    return "";
  }, [tasksQueryError, t]);

  return (
    <div className="flex flex-col h-full items-center">
      <AnonUserMessagePage
        title={t("Welcome")}
        subTitle={t("app.formTasks.dobEntryTitle")}
        logoUrl={practice.logo?.url}
      >
        <div className="w-48">
          <DateOfBirthForm
            error={errorMessage}
            displayErrorMessage={true}
            isLoading={isLoading}
            onChange={(selectedDob) => {
              onDateChange(selectedDob ? formatAsISODate(selectedDob) : undefined);
            }}
            onSubmit={() => onSubmit()}
          />
        </div>
      </AnonUserMessagePage>
    </div>
  );
};

import { SelfBookingOpenSlotsRequest } from "@libs/api/generated-api";
import { makeQuery } from "@libs/utils/queries";
import { cacheForSession, oneMinuteCache } from "@libs/utils/queryCache";

export const getSelfBookingConfigV2 = makeQuery({
  queryKey: ["public", "getSelfBookingConfigV2"],
  formatParams: (args: { practiceUuid: string }) => [args.practiceUuid],
  queryOptions: cacheForSession,
});

export const getInsuranceCarrierNames = makeQuery({
  queryKey: ["public", "getInsuranceCarrierNames"],
  formatParams: (args: { practiceUuid: string }) => [args.practiceUuid],
  queryOptions: cacheForSession,
});

export const getSelfBookableOpenSlotsV2 = makeQuery({
  queryKey: ["public", "getSelfBookableOpenSlotsV2"],
  formatParams: (args: { practiceUuid: string; data: SelfBookingOpenSlotsRequest }) => [
    args.practiceUuid,
    args.data,
  ],
  queryOptions: oneMinuteCache,
});

export const getSelfBookableProvidersV2 = makeQuery({
  queryKey: ["public", "getSelfBookableProvidersV2"],
  formatParams: ({
    practiceUuid,
    query,
  }: {
    practiceUuid: string;
    query: {
      carrierId?: number;
      appointmentCategoryId: number;
      startDate: string;
      selfBookingPatientType: "NEW" | "EXISTING";
    };
  }) => [practiceUuid, query],
  queryOptions: cacheForSession,
});

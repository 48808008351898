import { useEffect, useRef, useState } from "react";
import { minutesToMilliseconds } from "date-fns";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { isHttpResponseError } from "@libs/utils/isHttpResponseError";
import { StatusCodes } from "@libs/utils/statusCodes";
import { getPracticeInfoByUuid } from "api/user/queries";
import { KioskPinForm } from "components/PatientForms/PatientKiosk/KioskPinForm";
import { getPatientFormTasks, resolveKioskCode } from "api/forms/queries";
import { usePathParams } from "hooks/usePathParams";
import { PublicPracticeInstrumentation } from "components/Main/PublicPracticeInstrumentation";
import { LoadedPatientFormTasks } from "components/PatientForms/PatientFormTasks/LoadedPatientFormTasks";
import { PatientFormTasksDobPage } from "components/PatientForms/PatientFormTasks/PatientFormTasksDobPage";
import { FormTasksCompletedKiosk } from "components/PatientForms/PatientFormTasks/FormTasksCompletedKiosk";
import { hasExceededAttempts } from "utils/getIsKioskCodeOrDOBError";
import { FormMaxAttemptsExceeded } from "components/PatientForms/PatientFormTasks/FormMaxAttemptsExceeded";

const APP_RELOAD_MINUTES = 30;
const RELOAD_APP_TIMEOUT = minutesToMilliseconds(APP_RELOAD_MINUTES);

export const PatientKioskRoute: React.FC = () => {
  const pinFormRef = useRef<HTMLFormElement | null>(null);
  const { practiceUuid } = usePathParams("kiosk");
  const [pin, setPin] = useState("");
  const [pinError, setPinError] = useState("");
  const [dob, setDob] = useState<string | undefined>();

  const [resolveKioskCodeQuery, practiceQuery] = useApiQueries([
    resolveKioskCode({
      args: { practiceUuid, kioskCode: pin },
      queryOptions: {
        enabled: false,
        onError: (err) => {
          setPin("");

          if (
            isHttpResponseError(err) &&
            err.status === StatusCodes.BAD_REQUEST &&
            err.error.errors?.[0]?.message === "No form task uuids associated with kiosk code"
          ) {
            setPinError("Unsuccessful login attempt. Please try again.");
          } else {
            setPinError("Sorry, something went wrong. Please try again.");
          }

          pinFormRef.current?.querySelector("input")?.focus();
        },
      },
    }),

    getPracticeInfoByUuid({
      args: { practiceUuid },
    }),
  ]);

  const [tasksQuery] = useApiQueries([
    getPatientFormTasks({
      args: {
        practiceUuid,
        formTaskUuids: resolveKioskCodeQuery.data?.formTaskUuids ?? [],
        patientFormToken: resolveKioskCodeQuery.data?.patientFormToken ?? "",
        dob: dob ?? "",
      },
      queryOptions: {
        enabled: false,
      },
    }),
  ]);

  const handlePinChange = (newPin: string) => {
    setPin(newPin);
    setPinError("");
  };

  const fetchFormTasks = tasksQuery.refetch;

  // Reloads kiosk page when sitting
  // idle on either the pin or dob screen for 30 mimutes
  const hasLoadedTasks = Boolean(tasksQuery.data);

  useEffect(() => {
    if (!hasLoadedTasks) {
      const timeout = setTimeout(() => {
        window.location.reload();
      }, RELOAD_APP_TIMEOUT);

      return () => {
        clearTimeout(timeout);
      };
    }

    return undefined;
  }, [pin, dob, hasLoadedTasks]);

  return (
    <QueryResult queries={[practiceQuery]}>
      {practiceQuery.data ? (
        <>
          <PublicPracticeInstrumentation practice={practiceQuery.data} />
          {resolveKioskCodeQuery.data ? (
            hasExceededAttempts(tasksQuery.error) ? (
              <FormMaxAttemptsExceeded practice={practiceQuery.data} />
            ) : tasksQuery.data ? (
              <LoadedPatientFormTasks
                formTasksResponse={tasksQuery.data}
                practice={practiceQuery.data}
                patientFormToken={resolveKioskCodeQuery.data.patientFormToken}
                reloadAfterIdleMinutes={2}
                completedScreen={<FormTasksCompletedKiosk practice={practiceQuery.data} />}
              />
            ) : (
              <PatientFormTasksDobPage
                isLoading={tasksQuery.isFetching}
                onDateChange={setDob}
                onSubmit={fetchFormTasks}
                tasksQueryError={tasksQuery.error}
                practice={practiceQuery.data}
              />
            )
          ) : (
            <KioskPinForm
              formRef={pinFormRef}
              error={pinError}
              practice={practiceQuery.data}
              onSubmit={resolveKioskCodeQuery.refetch}
              onChange={handlePinChange}
              pin={pin}
            />
          )}
        </>
      ) : null}
    </QueryResult>
  );
};

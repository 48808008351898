import { Link } from "react-router-dom";
import { cx } from "@libs/utils/cx";
import { Icon } from "@libs/components/UI/Icon";
import { semibold14 } from "assets/styles/textSize";

const cxStyles = {
  container: "flex py-2 gap-2 items-center",
};

export const ItemContents: React.FC<{
  SvgIcon: IconComponent;
  children: React.ReactNode;
}> = ({ SvgIcon, children }) => {
  return (
    <>
      <Icon SvgIcon={SvgIcon} />
      <div className={semibold14}>{children}</div>
    </>
  );
};

export const AccountLinkItem: React.FC<{
  to: string;
  SvgIcon: IconComponent;
  children: React.ReactNode;
  external?: boolean;
  className?: string;
}> = ({ to, external, className, ...rest }) => {
  const containerClassName = cx(className, cxStyles.container);

  return external ? (
    <a className={containerClassName} href={to}>
      <ItemContents {...rest} />
    </a>
  ) : (
    <Link to={to} className={containerClassName}>
      <ItemContents {...rest} />
    </Link>
  );
};
